import { auth } from '@lecar/firebase/auth';
import { onAuthStateChanged, User } from 'firebase/auth';
import { atom, batched, onMount } from 'nanostores';
import { LoadableAtom } from '@lecar/interfaces/index';

export interface MountableAtom<T> extends LoadableAtom<T> {
  mounted: boolean;
}

export const $user = atom<MountableAtom<User | null>>({
  isLoading: true,
  mounted: false,
  value: null,
});

export const $userCachedDisplayName = atom<string | undefined>();

const titleCaseSentence = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const $userName = batched(
  [$user, $userCachedDisplayName],
  (user, userCachedDisplayName) => {
    const fullName = titleCaseSentence(
      user.value?.displayName || userCachedDisplayName || ''
    );
    const firstName = fullName.split(' ')[0] || '';
    const lastName = fullName.split(' ')[1] || '';
    return {
      fullName,
      firstName,
      lastName,
      initials: `${firstName.charAt(0)}${lastName?.charAt(0)}`,
    };
  }
);

onMount($user, () => {
  if (!auth) return;

  $user.set({
    ...$user.get(),
    mounted: true,
  });

  const unsubAuth = onAuthStateChanged(auth, (user) => {
    $user.set({
      ...$user.get(),
      isLoading: false,
      value: user,
    });
  });

  return () => {
    unsubAuth();
    $user.set({
      ...$user.get(),
      mounted: false,
      value: null,
    });
  };
});
