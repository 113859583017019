'use client';
import Image from 'next/image';
import React, { useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import { useStore } from '@nanostores/react';
import { $userName } from '@lecar/stores/user/user';
import { Dropdown } from 'flowbite-react';
import { signOut } from 'firebase/auth';
import { auth } from '@lecar/firebase/auth';
import { routes } from '../../../utils/routes';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { cn } from '../base/utils';
import LanguageSwitcher from '../language-switcher';
import { useTranslations } from '../../hooks/useTranslations';

export const Header = ({ darkMode }: { darkMode?: boolean }) => {
  const t: any = useTranslations();
  const [menuOpen, setMenuOpen] = useState(false);
  const { firstName } = useStore($userName);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);
  const isNewsroom = usePathname()?.includes('/newsroom');

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const MenuItems = ({ isMobile = false }: { isMobile?: boolean }) => (
    <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 text-lg">
      {[
        { href: routes.HOME, label: t.menu.home },
        { href: routes.NEWSROOM, label: t.menu.pressRoom },
        { href: routes.CONTACT, label: t.menu.contact },
      ].map((item) => (
        <li key={item.href}>
          <Link
            href={item.href}
            className={`text-base ${
              isMobile || darkMode ? 'text-white' : 'text-black-1000'
            } hover:underline`}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <header
      className={`${
        isNewsroom ? 'relative' : 'absolute'
      } w-full z-30 animate__animated animate__fadeIn animate__delay-0.5s`}
      aria-label="Cabeçalho do site"
    >
      <div className="flex px-8 max-w-screen-2xl mx-auto justify-between items-center h-28">
        <Link
          href={routes.HOME}
          className="flex items-center"
          aria-label="Logo da Lecar"
        >
          <Image
            src="/img/logo.png"
            alt="Logo da Lecar"
            width={182}
            height={32}
            className={cn(
              'w-[182px] h-[32px] filter',
              darkMode ? '' : 'invert'
            )}
          />
        </Link>
        <nav
          className="hidden md:flex items-center space-x-8"
          aria-label="Navegação principal"
        >
          <MenuItems />
          <LanguageSwitcher />
          {firstName && (
            <Dropdown
              label={
                <div className="flex flex-row space-x-4 items-center cursor-pointer">
                  <FaUserCircle size={24} />
                  <span>{firstName}</span>
                </div>
              }
              inline={true}
              arrowIcon={false}
            >
              <Dropdown.Item onClick={handleSignOut}>
                <div className="flex items-center">
                  <FaSignOutAlt className="mr-2" />
                  Sair da conta
                </div>
              </Dropdown.Item>
            </Dropdown>
          )}
        </nav>
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="focus:outline-none"
            aria-label="Abrir menu"
          >
            <FiMenu size={24} />
          </button>
        </div>
      </div>

      {menuOpen && (
        <>
          <div
            className={`fixed inset-0 bg-black bg-opacity-50 z-20 transition-opacity duration-300 ${
              menuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
            }`}
            onClick={closeMenu}
            aria-label="Fechar menu"
          ></div>
          <div
            className={`fixed top-0 left-0 w-full h-auto bg-[#060606] z-30 p-8 transform transition-transform duration-300 ease-in-out ${
              menuOpen ? 'translate-y-0' : '-translate-y-full'
            }`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-8">
              <Link href={routes.HOME}>
                <Image
                  src="/img/logo.png"
                  alt="Logo da Lecar"
                  width={182}
                  height={32}
                  className="w-[182px] h-[32px]"
                />
              </Link>
              <button
                onClick={closeMenu}
                className="focus:outline-none"
                aria-label="Fechar menu"
              >
                <FiX size={24} className="text-white" />
              </button>
            </div>
            <MenuItems isMobile={true} />
          </div>
        </>
      )}
    </header>
  );
};
