'use client';

import { usePathname, useRouter } from 'next/navigation';
import { useState, useCallback, useEffect, useRef } from 'react';
import { FiGlobe } from 'react-icons/fi';

const languages = [
  { code: 'pt-BR', label: 'PT' },
  { code: 'en-US', label: 'EN' },
  { code: 'zh-CN', label: 'CN' },
  { code: 'es-ES', label: 'ES' },
];

export default function LanguageSwitcher() {
  const pathname = usePathname();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const currentLang = pathname.split('/')[1] || 'pt-BR';
  const currentLabel =
    languages.find((lang) => lang.code === currentLang)?.label || 'PT';

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const switchLanguage = useCallback(
    (langCode: string) => {
      // Saving language into cookie
      document.cookie = `locale=${langCode}; path=/; max-age=31536000;`;
      // Update URL
      const newPathname = pathname.replace(/^\/[^/]+/, `/${langCode}`);
      router.push(newPathname);
      setIsOpen(false);
    },
    [pathname, router]
  );

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex gap-2 items-center justify-center w-10 h-10 text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none"
      >
        <FiGlobe />
        {currentLabel}
      </button>

      {isOpen && (
        <div className="absolute right-0 bg-transparent rounded shadow-xl z-50">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => switchLanguage(lang.code)}
              className={`block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 ${
                currentLang === lang.code ? 'font-semibold' : ''
              }`}
            >
              {lang.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
