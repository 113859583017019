// READ THIS BEFORE REFACTORING
// this is here for multiple reasons
// - next js code splitting can't ensure firebase will be initialized
// - next js server side rendering can't init firebase
// - compat with other applications that aren't next js
// I already attempted to transform this into a Provider

import { getApps, initializeApp } from 'firebase/app';

export const useEmulators = !!process.env.NEXT_PUBLIC_USE_EMULATOR;

export const isLocalhost =
  typeof window !== 'undefined' && window.location.hostname === 'localhost';

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
} as Record<string, string>;

export const initFirebase = () =>
  !getApps().length && initializeApp(firebaseConfig);

export const ensure = <T>(
  callback: () => T,
  availableOnNode?: boolean
): T | undefined => {
  if (!availableOnNode && typeof window === 'undefined') return undefined;
  initFirebase();
  return callback();
};
