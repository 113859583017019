'use client';

import { FaCircleArrowRight } from 'react-icons/fa6';
import { cn } from '../base/utils';
import { useRouter } from 'next/navigation';

interface OrderButtonProps {
  isOutline?: boolean;
  text: string;
}

export const OrderButton: React.FC<OrderButtonProps> = ({
  isOutline = false,
  text,
}) => {
  const router = useRouter();

  const handleClick = () => {
    router.push('/purchase');
  };

  return (
    <div
      className={cn(
        'opacity-0',
        'opacity-100 animate__animated animate__fadeIn animate__delay-0.5s'
      )}
    >
      {isOutline ? (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          className="flex items-center bg-white min-w-[140px] max-w-[200px] h-[48px] px-4 gap-2 rounded-full opacity-100 hover:bg-gray-200 transition-colors whitespace-nowrap"
        >
          <span className="text-black-1000">{text}</span>

          <FaCircleArrowRight className="text-black-1000 w-6 h-6" />
        </a>
      ) : (
        <button
          onClick={handleClick}
          className="flex items-center bg-blue-700 min-w-[140px] max-w-[200px] h-[48px] px-4 gap-2 rounded-full opacity-100 text-white hover:bg-blue-600 transition-colors whitespace-nowrap"
        >
          {text}
          <FaCircleArrowRight className="text-white w-6 h-6 ml-2" />
        </button>
      )}
    </div>
  );
};
