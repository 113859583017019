'use client';

import Image from 'next/image';
import React from 'react';
import { OrderButton } from './OrderButton';
import { Header } from './Header';
import { useTranslations } from '../../hooks/useTranslations';

const heroUrl = '/img/hero.png';

export const Hero = () => {
  const t: any = useTranslations();

  const specifications = [
    { value: '10,9 s', label: t.hero.specifications.zeroToHundred },
    { value: '1.000 km', label: t.hero.specifications.autonomy },
    { value: '165 cv', label: t.hero.specifications.hybrid },
    { value: '08/2026', label: t.hero.specifications.production },
    { value: '3 '+ t.hero.years, label: t.hero.specifications.warranty },
    { value: '10 '+ t.hero.years, label: t.hero.specifications.batteryWarranty },
  ];
  return (
    <section
      className="relative bg-[#D1D1D1] text-black-1000 h-[100svh] sm:h-screen flex flex-col"
      aria-labelledby="hero-section"
    >
      <Header />

      <div className="flex flex-col flex-1 min-h-0">
        {/* min-h-0 é crucial para evitar overflow */}
        <div className="relative flex items-center justify-center flex-1 min-h-0 px-4">
          {/* min-h-0 aqui também */}
          <div className="relative w-full max-w-[1440px] h-full mt-[120px]  max-h-[60vh]">
            {/* limitando altura da imagem */}
            <Image
              src={heroUrl}
              alt="Imagem do carro Lecar 459 Híbrido em destaque"
              fill
              className="object-contain"
              quality={75}
              sizes="(max-width: 1440px) 100vw, 1440px"
              priority
            />
          </div>
        </div>
        <div className="w-full bg-[#D1D1D1] mt-auto">
          {/* mt-auto empurra para baixo */}
          <div className="px-4 py-6 mx-auto max-w-screen-2xl md:px-8">
            {/* reduzido padding */}
            {/* Desktop Layout */}
            <div className="items-start justify-between hidden md:flex">
              {/* Left side - Price and Specifications */}
              <div className="flex-1">
                <div className="mb-3 space-y-1">
                  {/* reduzido spacing */}
                  <p className="text-2xl font-normal leading-tight">
                    {t.hero.title}
                  </p>
                  <p className="text-sm font-normal leading-tight">
                    {t.hero.subtitle}
                  </p>
                </div>

                <hr className="my-3 border-t border-white/20" />

                <div className="grid grid-cols-6 gap-6">
                  {/* reduzido gap */}
                  {specifications.map((spec, index) => (
                    <div
                      key={index}
                      className="text-left animate__animated animate__fadeInUp"
                      style={{ animationDelay: `${0.2 * (index + 1)}s` }}
                    >
                      <p className="text-lg font-normal">{spec.value}</p>
                      <p className="text-sm font-normal">{spec.label}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Right side - Button */}
              <div className="ml-8">
                <OrderButton text={t.orderButton} />
              </div>
            </div>
            {/* Mobile Layout */}
            <div className="md:hidden">
              <div className="mb-3 space-y-1">
                <p className="text-2xl font-normal leading-tight">
                  {t.hero.title}
                </p>
                <p className="text-sm font-normal leading-tight">
                  {t.hero.subtitle}
                </p>
              </div>

              <hr className="my-3 border-t border-white/20" />

              <div className="grid grid-cols-3 gap-4">
                {specifications.map((spec, index) => (
                  <div
                    key={index}
                    className="text-left animate__animated animate__fadeInUp"
                    style={{ animationDelay: `${0.2 * (index + 1)}s` }}
                  >
                    <p className="text-base font-normal">{spec.value}</p>
                    <p className="text-xs font-normal">{spec.label}</p>
                  </div>
                ))}
              </div>

              <div className="flex justify-center mt-4">
                <OrderButton text={t.orderButton} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
